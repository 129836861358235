@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Jockey+One&display=swap');

*{
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

html{
  scroll-behavior: smooth;
}

:root{
  --yellow: #FFC60D;
  --black: #1e1e1e;
  --green: #516329;
  --gray: #E8E8E8;
  --darkgray: #D9D9D9;
  --white: #FAFAFA;
}

body{
  background-color: #E8E8E8;
}