.menuhome{
    width: 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='2500' height='600' preserveAspectRatio='none' viewBox='0 0 2500 600'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1030%26quot%3b)' fill='none'%3e%3crect width='2500' height='600' x='0' y='0' fill='rgba(81%2c 99%2c 41%2c 1)'%3e%3c/rect%3e%3cpath d='M2500 0L1335.65 0L2500 76.17z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1335.65 0L2500 76.17L2500 315.92L1033.0100000000002 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M1033.0100000000002 0L2500 315.92L2500 316.28000000000003L516.3200000000002 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M516.3200000000002 0L2500 316.28000000000003L2500 339.90000000000003L272.8600000000001 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 600L247.76 600L0 328.93z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 328.93L247.76 600L464.67999999999995 600L0 241.36z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 241.36L464.67999999999995 600L898.9499999999999 600L0 183.41000000000003z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 183.41000000000003L898.9499999999999 600L2024.37 600L0 140.28000000000003z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1030'%3e%3crect width='2500' height='600' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}

header{
    padding: 25px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

header img{
    border-radius: 50%;
    width: 120px;
    filter: drop-shadow(0px 0px 20px var(--yellow));
    cursor: pointer;
}

header ul{
    list-style: none;
    display: flex;
    gap: 35px;
}

header ul a{
    text-decoration: none;
    font-size: 18px;
    font-family: "Inter", sans-serif;
    color: white;
    font-weight: 500;
    text-shadow: 0px 0px 7px #353535;
}

header ul a:hover{
    color: #FFC60D;
}

.primary{
    background-color: var(--yellow);
    border: none;
    border-radius: 7px;
    padding: 10px 15px;
    color: var(--green);
    font-size: 18px;
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: 0.3s;
}

.primary:hover{
    transition: 0.3s;
    background-color: #cea00b;
}

nav{
    display: none;
}

@media only screen and (max-width: 1080px) and (min-width: 768px){
 
    header{
        padding-inline: 5%;
    }

    header img{
        width: 90px;
    }

    header ul a{
        font-size: 16px;
    }

    .primary{
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px){
    nav{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    nav img{
        width: 90px;
        border-radius: 50%;
        filter: drop-shadow(0px 0px 10px var(--green));
    }

    header{
        display: none;
    }

    #navheader{
        display: flex;
        padding: 20px 5%;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: var(--white);
        z-index: 300;
    }

    .iconnav{
        width: 35px;
        height: 35px;
        color: var(--green);
    }

    #navbody{
        display: none;
        z-index: 999;
        background-color: var(--white);
        position: absolute;
        top: 0;
        width: 100%;
    }

    #navbody ul{
        padding: 30px 6%;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    #navbody a{
        color: var(--green);
        font-weight: 600;
        font-family: "Inter", sans-serif;
        text-decoration: none;
        font-size: 18px;        
    }
}

#homemenupart{
    padding: 40px 13%;
}

#homemenupart p{
    color: var(--yellow);
    font-family: "Jockey One", sans-serif;
    font-size: 6vw;
}

@media only screen and (max-width: 1100px) and (min-width: 768px){
    #homemenupart p{
        font-size: 6vw;
    }
}

@media only screen and (max-width: 768px) and (min-width: 500px){

    #homemenupart p{
        font-size: 9vw;
    }
}

@media only screen and (max-width: 500px){
    .menuhome{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='500' height='400' preserveAspectRatio='none' viewBox='0 0 500 400'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1009%26quot%3b)' fill='none'%3e%3crect width='500' height='400' x='0' y='0' fill='rgba(81%2c 99%2c 41%2c 1)'%3e%3c/rect%3e%3cpath d='M500 0L266.53999999999996 0L500 44.71z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M266.53999999999996 0L500 44.71L500 142.23L189.09999999999997 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M189.10000000000002 0L500 142.23L500 294.61L124.72000000000003 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M124.72000000000003 0L500 294.61L500 295.99L75.97000000000003 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 400L22.47 400L0 299.01z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 299.01L22.47 400L119.38 400L0 216.57999999999998z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 216.57999999999998L119.38 400L214.39 400L0 164.75z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 164.75L214.39 400L215.51999999999998 400L0 118.41z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1009'%3e%3crect width='500' height='400' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    }

    #homemenupart{
        padding-inline: 5%;
    }

    #homemenupart p{
        font-size: 50px;
    }
}

#menus{
    padding: 50px 13%;
    display: grid;
    grid-template-columns: repeat(3, 27%);
    column-gap: 9.5%;
    row-gap: 40px;
}

#menus img{
    width: 100%;
    border-radius: 0px 10px;
    border: 2px solid var(--yellow);
}

@media only screen and (max-width: 950px) and (min-width: 600px){
    #menus{
        grid-template-columns: repeat(2, 45%);
        column-gap: 10%;
        padding-inline: 8%;
    }
}

@media only screen and (max-width: 600px){
    #menus{
        grid-template-columns: repeat(1, 90%);
        place-content: center;
        column-gap: 0;
        padding-inline: 8%;
    }
}

footer{
    background-color: var(--green);
    padding: 30px 13%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid var(--yellow);
}

footer img{
    width: 100px;
    border-radius: 50%;
    filter: drop-shadow(0px 0px 5px var(--yellow));
}

footer>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

footer p{
    font-family: "Jockey One", sans-serif;
    color: var(--white);
    font-size: 25px;
    border-bottom: 1px solid var(--yellow);
    padding-inline: 4px;
}

footer a{
    color: var(--white);
    text-decoration: none;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

footer>div>div{
    display: flex;
    gap: 15px;
}

.iconfooter{
    width: 30px;
    height: 30px;
}

@media only screen and (max-width: 1000px){
    footer{
        padding-inline: 5%;
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media only screen and (max-width: 800px) and (min-width: 600px){
    footer p{
        font-size: 20px;
    }

    footer a{
        font-size: 13px;
    }

    .iconfooter{
        width: 23px;
        height: 23px;
    }
}

@media only screen and (max-width: 600px){
    footer{
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    footer>div{
        align-items: flex-start;
    }
}

#copyright{
    display: flex;
    justify-content: center;
    height: 40px;
    background-color: var(--green);
    color: var(--gray);
    cursor: pointer;
    align-items: center;
}