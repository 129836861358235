#home{
    width: 100%;
    background-image: linear-gradient(to bottom, #00000091, #ffc70d1c),
    url('./../../assets/home.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

header{
    padding: 25px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

header img{
    border-radius: 50%;
    width: 120px;
    filter: drop-shadow(0px 0px 20px var(--yellow));
    cursor: pointer;
}

header ul{
    list-style: none;
    display: flex;
    gap: 35px;
}

header ul a{
    text-decoration: none;
    font-size: 18px;
    font-family: "Inter", sans-serif;
    color: white;
    font-weight: 500;
    text-shadow: 0px 0px 7px #353535;
}

header ul a:hover{
    color: #FFC60D;
}

.primary{
    background-color: var(--yellow);
    border: none;
    border-radius: 7px;
    padding: 10px 15px;
    color: var(--green);
    font-size: 18px;
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: 0.3s;
}

.primary:hover{
    transition: 0.3s;
    background-color: #cea00b;
}

nav{
    display: none;
}

@media only screen and (max-width: 1080px) and (min-width: 768px){
 
    header{
        padding-inline: 5%;
    }

    header img{
        width: 90px;
    }

    header ul a{
        font-size: 16px;
    }

    .primary{
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px){
    nav{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    nav img{
        width: 90px;
        border-radius: 50%;
        filter: drop-shadow(0px 0px 10px var(--green));
    }

    header{
        display: none;
    }

    #navheader{
        display: flex;
        padding: 20px 5%;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: var(--white);
        z-index: 300;
    }

    .iconnav{
        width: 35px;
        height: 35px;
        color: var(--green);
    }

    #navbody{
        display: none;
        z-index: 999;
        background-color: var(--white);
        position: absolute;
        top: 0;
        width: 100%;
    }

    #navbody ul{
        padding: 30px 6%;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    #navbody a{
        color: var(--green);
        font-weight: 600;
        font-family: "Inter", sans-serif;
        text-decoration: none;
        font-size: 18px;        
    }
}

#homepart{
    padding: 100px 13% !important;
    padding-bottom: 200px !important;
}

#homepart p{
    color: var(--yellow);
    font-family: "Jockey One", sans-serif;
    font-size: 4vw;
}

#homepart h1{
    font-family: "Inter", sans-serif;
    font-size: 5vw;
    color: var(--white);
}

#homepart h5{
    color: white;
    font-weight: 400;
    font-size: 18px;
    text-shadow: 0px 0px 5px gray;
}

#homepart span{
    color: var(--yellow);
    font-weight: 700;
}

@media only screen and (max-width: 1100px) and (min-width: 768px){
    #homepart p{
        font-size: 5.2vw;
    }

    #homepart h1{
        font-size: 6vw;
    }

    #homepart h5{
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 500px){
    #homepart{
        padding-bottom: 150px !important;
    }

    #homepart p{
        font-size: 9vw;
    }

    #homepart h1{
        font-size: 8vw;
    }

    #homepart h5{
        font-size: 2.7vw;
    }
}

@media only screen and (max-width: 500px){
    #home{
        background-image: linear-gradient(to bottom, #000000be, #91710a1c),
    url('./../../assets/home.png');
    }

    #homepart{
        padding-bottom: 150px !important;
        padding-inline: 5%;
    }

    #homepart p{
        font-size: 45px;
    }

    #homepart h1{
        font-size: 40px;
        text-shadow: 0px 0px 2px 0px black;
    }

    #homepart h5{
        font-size: 14px;
    }
}

#about{
    padding: 70px 3%;
    margin-left: 150px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#restaurantphoto{
    width: 30%;
    position: relative;
    height: 30vw;
}

#tavolinaphoto{
    width: 30%;
    position: relative;
    height: 30vw;
}

.blackshadow{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000050;
    z-index: 100;
    border-radius: 15px;
}

#about img{
    object-fit: cover;
    width: 100%;
    border-radius: 15px;
    vertical-align: top;
    height: 100%;
}

#aboutdiv{
    border: 1px solid var(--yellow);
    background-color: var(--white);
    border-radius: 15px;
    padding: 70px 40px;
    position: relative;
    right: 8vw;
    bottom: 5vw;
    z-index: 300;
    width: 33%;
}

#aboutdiv p{
    font-family: "Jockey One", sans-serif;
    color: var(--yellow);
    font-size: 2.7vw;
    text-align: center;
}

#aboutdiv h1{
    font-family: "Inter", sans-serif;
    font-weight: 800;
    color: var(--black);
    text-align: center;
    font-size: 3vw;
}

#aboutdiv h6{
    text-align: center;
    color: var(--black);
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 0.8vw;
}

@media only screen and (max-width: 1200px) and (min-width: 1000px){
    #aboutdiv h6{
        font-size: 11px;
    }

    #about{
        margin-left: 50px;
    }

    #aboutdiv{
        padding: 55px 30px;
    }
}

@media only screen and (max-width: 1000px) and (min-width: 800px){
    #aboutdiv h6{
        font-size: 11px;
    }

    #about{
        margin-left: 0px;
        padding-inline: 3%;
    }

    #aboutdiv{
        padding: 45px 20px;
        width: 35%;
    }
}

@media only screen and (max-width: 800px) and (min-width: 650px){
    #about{
        margin-left: 0px;
        padding-inline: 3%;
    }

    #restaurantphoto, #tavolinaphoto{
        height: 45vw;
    }

    #aboutdiv h6{
        font-size: 11px;
    }

    #aboutdiv p{
        font-size: 3vw;
    }   

    #aboutdiv h1{
        font-size: 4vw;
    }

    #aboutdiv{
        padding: 45px 20px;
        width: 35%;
    }
}

@media only screen and (max-width: 650px){

    #about{
        flex-wrap: wrap;
        margin-left: 0px;
        padding: 10px 3%;
    }

    #restaurantphoto, #tavolinaphoto{
        width: 45vw;
        height: 50vw;
    }

    #aboutdiv{
        bottom: 0;
        right: 0;
        margin-top: 20px;
        width: 100%;
        padding: 40px 20px;
    }

    #aboutdiv h6{
        font-size: 13px;
    }

    #aboutdiv p{
        font-size: 30px;
    }   

    #aboutdiv h1{
        font-size: 40px;
    }
}

@media only screen and (max-width: 450px){
    #restaurantphoto, #tavolinaphoto{
        width: 100vw;
        height: 50vw;
    }

    #tavolinaphoto{
        margin-top: 20px;
    }
}

#amazing{
    width: 100%;
    padding: 90px 0;
    background-image: linear-gradient(to bottom, #000000a2, #0000007c),
    url('./../../assets/family.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

#amazing p{
    color: var(--yellow);
    font-size: 3.5vw;
    font-family: "Jockey One", sans-serif;
    text-align: center;
}

#amazing h1{
    font-family: "Inter", sans-serif;
    font-weight: 800;
    color: var(--white);
    text-align: center;
    font-size: 5vw;
}

@media only screen and (max-width: 1300px) and (min-width: 900px){
    #amazing p{
        font-size: 4vw;
    }

    #amazing h1{
        font-size: 6vw;
    }
}

@media only screen and (max-width: 900px) and (min-width: 650px){
    #amazing p{
        font-size: 5vw;
    }

    #amazing h1{
        font-size: 7vw;
    }
}

@media only screen and (max-width: 650px){
    #amazing{
        margin-top: 20px;
    }

    #amazing p{
        font-size: 40px;
    }

    #amazing h1{
        font-size: 50px;
    }
}

.menusection{
    padding: 70px 7%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#menuitems{
    width: 60%;
    display: grid;
    grid-template-columns: repeat(2, 47.5%);
    gap: 5%;
}

#menuitems div{
    aspect-ratio: 3/2;
}

#menuitems img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

#fliimg{
    position: relative;
}

.menucheckout{
    width: 40%;
    position: relative;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5vw 30px;
    background-color: var(--darkgray);
    border: 1px solid var(--green);
    border-radius: 15px;
    gap: 3vw;
    text-align: center;
}

.menucheckout p{
    font-family: "Jockey One", sans-serif;
    color: var(--green);
    font-size: 2.7vw;
    text-align: center;
}

.menucheckout h1{
    font-family: "Inter", sans-serif;
    font-weight: 800;
    color: var(--black);
    text-align: center;
    font-size: 3vw;
}

.menucheckout h5{
    font-weight: 500;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
}

.menucheckout a{
    color: var(--green);
    text-decoration: none;
    border-bottom: 2px solid var(--green);
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 1.6vw;
}

@media only screen and (max-width: 1000px) and (min-width: 650px){
    .menucheckout h5{
        font-size: 13px;
    }

    .menucheckout a{
        font-size: 1.7vw;
    }
}

@media only screen and (max-width: 650px){
    .menusection{
        flex-direction: column-reverse !important;
        padding-top: 40px;
    }

    #menuitems, .menucheckout{
        width: 100%;
    }

    .menucheckout{
        right: 0;
        left: 0 !important;
        margin-bottom: 50px;
        gap: 40px;
    }

    .menucheckout p{
        font-size: 30px;
    }

    .menucheckout h1{
        font-size: 40px;
    }

    .menusection a{
        font-size: 16px;
    }
}

@media only screen and (max-width: 550px){
    #menuitems{
        grid-template-columns: repeat(1, 100%);
        gap: 10px;
    }
}

#restaurantsection{
    flex-direction: row-reverse;
}

#restaurantcheckout{
    right: 0;
    left: 20px;
}

#map{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 2%;
}

#map iframe{
    border: 0;
    border-radius: 10px;
}

@media only screen and (max-width: 750px){
    #map{
        padding: 10px;
    }

    #map iframe{
        width: 90%;
    }
}

footer{
    background-color: var(--green);
    padding: 30px 13%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid var(--yellow);
}

footer img{
    width: 100px;
    border-radius: 50%;
    filter: drop-shadow(0px 0px 5px var(--yellow));
}

footer>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

footer p{
    font-family: "Jockey One", sans-serif;
    color: var(--white);
    font-size: 25px;
    border-bottom: 1px solid var(--yellow);
    padding-inline: 4px;
}

footer a{
    color: var(--white);
    text-decoration: none;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

footer>div>div{
    display: flex;
    gap: 15px;
}

.iconfooter{
    width: 30px;
    height: 30px;
}

@media only screen and (max-width: 1000px){
    footer{
        padding-inline: 5%;
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media only screen and (max-width: 800px) and (min-width: 600px){
    footer p{
        font-size: 20px;
    }

    footer a{
        font-size: 13px;
    }

    .iconfooter{
        width: 23px;
        height: 23px;
    }
}

@media only screen and (max-width: 600px){
    footer{
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    footer>div{
        align-items: flex-start;
    }
}

#copyright{
    display: flex;
    justify-content: center;
    height: 40px;
    background-color: var(--green);
    color: var(--gray);
    cursor: pointer;
    align-items: center;
}